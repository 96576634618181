:root {
  --margin: 20px;
  --margin-2: 40px;
  --border-radius: 20px;
  --padding: 20px;
  --padding-2: 40px;
  --title-font: 1.5rem;
  --line-color: #E2E8F0;
  --box-shadow: 0 10px 10px #11047a07;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  color: rgb(13, 47, 68);
  background-color: #f5fff9;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

}

button, select, option, input, textarea {
  background: inherit;
  border: none;
  color: inherit;
  font-family: inherit;
}

select:focus {
  outline: none;
}

.container {
  margin-left: 320px;
  margin-top: 110px;
  margin-right: var(--margin);
  min-height: 100vh;
  z-index: 1;
  position: relative;
  margin-bottom: var(--margin);
  transition: margin 500ms cubic-bezier(0.770, 0.000, 0.175, 0.3);
  /* easeInOutQuart */

  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 0.3);
}

.container .container_page_title {
  flex-direction: column;
  margin: 20px 0 0;
  display: none;
}

.container .container_page_title .container_mini_title {
  font-size: .8rem;
  margin-bottom: .2rem;
  font-weight: 500;
}

.container .container_page_title .container_big_title {
  font-size: 2rem;
  font-weight: 600;
}

.confirm, .Dismiss {
  padding: 10px 20px;
  margin: 1vw .5vw 0;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: -webkit-filter .2s ease-in-out;
  transition: filter .2s ease-in-out;
  transition: filter .2s ease-in-out, -webkit-filter .2s ease-in-out;
}

.confirm {
  background-color: #129145;
  color: #f4f4f4;
}

.Dismiss {
  background-color: #A3AED040;
  color: #1B254B90;
}

.confirm:hover, .Dismiss:hover {
  -webkit-filter: brightness(50%);
  filter: brightness(50%);
}

.fb_loader {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeholder;
  animation-name: placeholder;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: linear-gradient(to right, #12914515 8%, #0c632f30 18%, #12914515 33%);
  background-size: 1200px 100px;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  z-index: 1;
}

@-webkit-keyframes placeholder {
  0% {
    background-position: -600px 0
  }

  100% {
    background-position: 600px 0
  }
}

@keyframes placeholder {
  0% {
    background-position: -600px 0
  }

  100% {
    background-position: 600px 0
  }
}


.loader_container_model_1 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.loader_container_model_2 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loader_container_model_3 {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}

.loader_model_1 {
  border-radius: 20px;
  margin: 1vw;
  overflow: hidden;

  width: 100%;
  height: 200px;
}

.loader_model_2 {
  border-radius: 20px;
  margin: 1vw;
  overflow: hidden;

  width: 50%;
  height: 70px;
}

.loader_model_3 {
  border-radius: 20px;
  margin: 1vw;
  overflow: hidden;

  width: 70%;
  height: 130px;
}

.start-loader-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(90deg, #129145, #fff);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 1s;
}

.start-loader {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
  scale: 2;
}

.start-loader:before {
  content: '';
  width: 48px;
  height: 5px;
  background: #000;
  opacity: 0.25;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  -webkit-animation: shadow 0.5s linear infinite;
          animation: shadow 0.5s linear infinite;
}

.start-loader:after {
  content: '';
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-animation: bxSpin 0.5s linear infinite;
          animation: bxSpin 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}

@-webkit-keyframes bxSpin {
  17% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes bxSpin {
  17% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@-webkit-keyframes shadow {
  0%, 100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}

@keyframes shadow {
  0%, 100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}


@media only screen and (max-width: 1200px) {
  .container {
    margin-left: 120px;
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    margin-top: 0;
    padding-bottom: 100px;
  }

  .container .container_page_title {
    display: flex;
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: var(--box-shadow);

  }

}