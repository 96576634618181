.navbar {
    display: flex;
    -webkit-font-smoothing: antialiased;
    overscroll-behavior-y: none;
  }
  
  .navbar .top {
    height: 80px;
    padding: 0 20px;
    margin-top: 30px;
    position: fixed;
    z-index: 9;
    padding-left: 320px;
    width: 100%;
    transition: padding 500ms cubic-bezier(0.770, 0.000, 0.175, 0.3);
    transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 0.3);
  
  }
  
  .navbar .top .background {
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(244, 247, 254, 0.2);
  
  }
  
  .navbar .top .background .page_name {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
  }
  
  .navbar .top .background .page_name .mini {
    font-size: .8rem;
    margin-bottom: .2rem;
    font-weight: 500;
  }
  
  .navbar .top .background .page_name .big {
    font-size: 2rem;
    font-weight: 600;
  }
  
  .navbar .top .background .buttons {
    display: flex;
    background-color: #fff;
    padding: .5rem;
    border-radius: 30px;
    box-shadow: var(--box-shadow);
  
  }
  
  .navbar .top .background .buttons .menu,
  .navbar .top .background .buttons .theme,
  .navbar .top .background .buttons .home,
  .navbar .top .background .buttons .size,
  .navbar .top .background .buttons .profile {
    margin: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    border: none;
    cursor: pointer;
    background-color: transparent;
  }
  
  .navbar .top .background .buttons .menu {}
  
  .navbar .top .background .buttons .size {
    font-size: 1.4rem;
  }
  
  .navbar .top .background .buttons .home {}
  
  .navbar .top .background .buttons .theme {}
  
  .navbar .top .background .buttons .profile {
    border-radius: 100%;
    font-size: 1.5rem;
    width: 3rem;
    height: 3rem;
    background-color: rgb(18, 145, 69);
    color: #fff;
    margin-right: 0;
  }
  
  
  
  .navbar .left {
    width: 300px;
    height: 100%;
    padding: 20px;
    display: flex;
    position: fixed;
    flex-direction: column;
    z-index: 10;
    transition: 500ms cubic-bezier(0.770, 0.000, 0.175, 0.3);
    transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 0.3);
  }
  
  .navbar .left .inside {
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(7, 59, 28, 0.08);
    display: flex;
    flex-direction: column;
    transition: 500ms cubic-bezier(0.770, 0.000, 0.175, 0.3);
    transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 0.3);
  }
  
  .navbar .left .back {
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: -1;
    position: absolute;
    pointer-events: none;
  }
  
  
  
  .navbar .left .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #E2E8F0;
    padding: 30px 0;
    margin-bottom: 30px;
  }
  
  .navbar .left .logo .img {
    position: relative;
    width: 100%;
    height: 50px;
  }
  
  .navbar .left .links {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
    overflow: auto;
    flex: 1;
  }
  
  
  
  .navbar .left .links .link {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #8f9bba;
    margin: 10px 0;
    padding: 10px 0;
    /* background-color: #d5d0ff; */
    /* color: #422AFB; */
    border-radius: 10px;
  }
  
  .navbar .left .links .link .head {
    display: flex;
    align-items: center;
    height: 25px;
    cursor: pointer;
  
    overflow: hidden;
  }
  
  .navbar .left .links .link .head .icon {
    width: 3rem;
    font-size: 1.7rem;
    display: flex;
    align-items: center;
  }
  
  .navbar .left .links .link .head .title {
    display: flex;
    justify-content: space-between;
    flex: 1;
    font-size: 1rem;
    white-space: nowrap;
    align-items: center;
  }
  
  .navbar .left .links .link .head .title .arrow {
    font-size: 1.2rem;
  }
  
  .navbar .left .links .link .options {
    margin-left: 3rem;
    overflow: hidden;
  }
  
  .navbar .left .links .link .options .option {
    height: 25px;
    display: flex;
    margin: 15px 0;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
  }
  
  .navbar .left .links::-webkit-scrollbar {
    width: 3px;
  }
  
  .navbar .left .links::-webkit-scrollbar-thumb {
    background: rgb(18, 145, 69,.3);
    border-radius: 5px;
  }
  
  .navbar .left .links:hover::-webkit-scrollbar-thumb {
    background-color: rgb(18, 145, 69);
  }
  
  .navbar .left .logoutBtn {
    width: 100%;
    height: 45px;
    font-weight: 600;
    background-color:  #e81e1e20;
    color:  #e81e1e;
    border-radius: 10px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s ease-in-out;
    cursor: pointer;
  }
  
  .navbar .left .logoutBtn:hover {
    -webkit-filter: brightness(0.8);
    filter: brightness(0.8  );
  }

  
  @media only screen and (max-width: 1024px) {
    .navbar .left {
      z-index: -1;
      pointer-events: none;
      background-color: transparent;
      transition: none;
    }
  
    .navbar .left.open_left_navbar {
      background-color: #0200127d;
      cursor: no-drop;
      pointer-events: all;
      z-index: 10;
    }
  
    .navbar .left .inside {
      transition: .1s ease-in-out;
    }
  
    .navbar .left .inside {
      transform: translateX(-150%);
      cursor: default;
      width: 280px;
    }
  
  
    .navbar .left .inside.open {
      transform: translateX(0);
    }
  }
  
  @media only screen and (max-width: 768px) {
    .navbar {
      display: flex;
      justify-content: center;
    }
  
    .navbar .top {
      overflow: visible;
      bottom: 20px;
      width: auto;
    }
  
    .navbar .top .background {
      padding: 10px;
      overflow: visible;
      justify-content: center;
      height: auto;
      box-shadow: 0 0 20px #11047a30;
      border-radius: 4rem;
    }
  
    .navbar .top .background .page_name {
      display: none;
    }
  
    .navbar .top .background .buttons .size {
      display: none;
    }
  
    .navbar .top .background .buttons {
      background-color: #ffffffdb;
    }
  
    .navbar .top .background .buttons .home,
    .navbar .top .background .buttons .menu,
    .navbar .top .background .buttons .profile {
      margin: 0 1.5rem;
    }
  
    .navbar .top .background .buttons .menu {
      margin-right: 1rem;
    }
  
    .navbar .top .background .buttons .profile {
      margin-right: 0;
    }
  
  
  }
  
  @media only screen and (max-width: 500px) {}
  
  @media only screen and (max-width: 425px) {
  
    .navbar .top {
      bottom: 10px;
    }
  
    .navbar .top .background .buttons {
      padding: .2rem;
    }
  
    .navbar .top .background .buttons .home,
    .navbar .top .background .buttons .menu,
    .navbar .top .background .buttons .profile {
      font-size: 1.5rem;
      margin: 0 1.2rem;
    }
  
    .navbar .top .background .buttons .menu {
      margin-right: .7rem;
    }
  
    .navbar .top .background .buttons .profile {
      margin-right: .2rem;
    }
  
  }
  
  @media only screen and (max-width: 390px) {}
  
  @media only screen and (max-width: 320px) {}