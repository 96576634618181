.loginPage {
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 20;
    position: fixed;
    inset: 0;

}

.loginPage .right,
.loginPage .left {
    width: 50%;
    padding: 7vw 10vw 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.loginPage .left .form_title {
    font-size: 2.5vw;
    font-weight: 600;

}

.loginPage .left .form_message {
    color: #A0Aec0;
    font-size: 1vw;
    padding-bottom: 3rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    border-bottom: solid 2px #A0Aec0db;
}

.loginPage .left .input_box {
    margin-bottom: 2rem;
}

.loginPage .left .input_box .input_title {
    font-size: .9vw;
    margin-bottom: .5rem;
    color: #798391;
}

.loginPage .left .input_box .myInput {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
}
.loginPage .left .input_box .myInput .error{
    color: #eb3745;
    font-size: .8rem;
    /* padding-top: .5vw; */
    width: 100%;
    text-align: right;
    position: absolute;
    bottom: -1.2rem;
}

.loginPage .left .input_box .myInput input {
    width: 100%;
    padding: 1.3vw;
    border-radius: 10px;
    border: solid 1.5px #a0aec04f;
    transition: .1s ease-in-out;
}

.loginPage .left .input_box .myInput input::-webkit-input-placeholder {
    color: #a0aec09e;

}

.loginPage .left .input_box .myInput input::-moz-placeholder {
    color: #a0aec09e;

}

.loginPage .left .input_box .myInput input:-ms-input-placeholder {
    color: #a0aec09e;

}

.loginPage .left .input_box .myInput input::-ms-input-placeholder {
    color: #a0aec09e;

}

.loginPage .left .input_box .myInput input::placeholder {
    color: #a0aec09e;

}

.loginPage .left .input_box .myInput input:focus {
    outline: none;
    border: solid 1.5px #a0aec0;
}

.loginPage .left .input_box .myInput .eye_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    right: 1rem;
    font-size: 1.2vw;
    color: #a0aec0db;
    cursor: pointer;
}


.loginPage .left .reset_password {
    color: #422AFB;
    width: 100%;
    font-size: .8vw;
    text-align: right;
    transform: translateY(-100%);
}

.loginPage .left .login_submit {
    padding: 20px 0;
    width: 100%;
    background-color: rgb(18, 145, 69,.9);
    font-size: 1.2vw;
    font-weight: 600;
    border-radius: 20px;
    color: #f1f8ff;
    transition: .2s ease-in-out;
    cursor: pointer;
}

.loginPage .left .login_submit:hover {
    -webkit-filter:brightness(.8);
            filter:brightness(.8);
}

.loginPage .right {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom-left-radius: 200px;
    overflow: hidden;
}

.loginPage .right .background {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    inset: 0;
}
.loginPage .right .background::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0006;
    isolation: isolate;
}

.loginPage .right .logo {
    position: relative;
    width: 45%;
    z-index: 2;
    height: 150px;
}

.loginPage .right .message {
    padding: 20px 0;
    width: 60%;
    border: solid #a0aec04f 2px;
    color: #f1f8ff;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2;
    flex-direction: column;
    margin-top: 3rem;
    text-align: center;
    border-radius: 10px;
}

@media only screen and (max-width:1024px) {
    .loginPage .left {
        padding: 7vw 7vw 0;
    }

    .loginPage .left .form_title {
        font-size: 5vw;

    }

    .loginPage .left .form_message,
    .loginPage .left .input_box .input_title {
        font-size: 1.7vw;

    }

    .loginPage .left .input_box .myInput input {
        font-size: 2vw;
    }

    .loginPage .left .login_submit {
        font-size: 2.5vw;
    }




}

@media only screen and (max-width: 768px) {
    .loginPage {
        position: relative;
    }

    .loginPage .right {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        inset: 0;
        border-bottom-left-radius: 0;
    }



    .loginPage .right .logo,
    .loginPage .right .message {
        display: none;
    }

    .loginPage {
        padding: 10vw 15vw;
        align-items: center;
    }

    .loginPage .left {
        z-index: 1;
        width: 100%;
        height: auto;
        background-color: #e9f4ff28;
        justify-content: center;
        padding: 7vw;
        border-radius: 30px;
        border-bottom-left-radius: 100px;
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
    }

    .loginPage .left .form_title {
        color: #fff;
        font-size: 6vw;
    }

    .loginPage .left .form_message {
        font-size: 2.5vw;
    }

    .loginPage .left .input_box .input_title {
        font-size: 2.5vw;
        color: #a0aec0;
    }

    .loginPage .left .input_box .myInput input {
        color: #fff;
        font-size: 3vw;
        padding: 2vw;
    }

    .loginPage .left .input_box .myInput .eye_icon {
        font-size: 3.5vw;
    }

    .loginPage .left .reset_password {
        color: #fff;
        font-size: 1.5vw;
    }
}

@media only screen and (max-width: 500px) {
    .loginPage {
        padding: 5vw;
    }

    .loginPage .left {
        padding: 10vw;
    }

    .loginPage .left .form_title {
        color: #fff;
        font-size: 8vw;
    }

    .loginPage .left .form_message {
        font-size: 3vw;
    }

    .loginPage .left .input_box .input_title {
        font-size: 3vw;
        color: #a0aec0;
    }

    .loginPage .left .input_box .myInput input {
        color: #fff;
        font-size: 4vw;
        padding: 3vw;
    }

    .loginPage .left .input_box .myInput .eye_icon {
        font-size: 4vw;
    }

    .loginPage .left .reset_password {
        color: #fff;
        font-size: 2vw;
    }

    .loginPage .left .login_submit {
        font-size: 3.5vw;
        padding: 15px;
        border-radius: 15px;
    }

}

@media only screen and (max-width: 425px) {}

@media only screen and (max-width: 390px) {
    .loginPage .left {
        padding: 12.5vw;
    }

}

@media only screen and (max-width: 320px) {}