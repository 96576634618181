.tables {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #272864;
    position: relative;
    align-items: flex-end;
    padding-top: var(--padding);
}

.tables .big_box {
    display: flex;
    flex-direction: column;
    color: #272864;
    border-radius: 10px;
    position: relative;
    z-index: 2;
    width: -webkit-fill-available;
    width: -moz-available;
    background-color: #fff;
    box-shadow: var(--box-shadow);
    padding: 30px 30px 40px;
}


.tables .big_box .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    margin-bottom: 20px;
}

.tables .big_box .options .left {
    display: flex;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}



.tables .big_box .options .left .sortBox {
    font-size: 1vw;
    box-shadow: 0 0 0 5px #12914526;
    margin: 7px 7px 0;
    font-weight: 500;
    padding: 10px 20px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-radius: 10px;
    text-transform: uppercase;
    white-space: nowrap;
}

.tables .big_box .options .left .sym {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.7vw;
    font-size: 1.8vw;

}

.tables .big_box .options .center {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 13px 0;
    width: 100%;

}

.tables .big_box .options .center .add_btn,
.tables .big_box .options .center .dlt_btn {
    cursor: pointer;
    padding: 10px 30px;
    margin: 0 10px;
    font-size: 1.2vw;
    border-radius: 7px;
    transition: .4s;
}

.tables .big_box .options .center .add_btn {
    background-color: #91d99c;
    color: #fff;
}

.btn_prem {
    background: linear-gradient(135deg, #0d6c3390, #0d6c3390, #dae8e040, #dae8e040, #0d6c3390);
    padding: .5vw 1vw;
    color: #FAF8FF;
    font-size: 1.2vw;
    transition: .4s;
    margin: 10px 0;
    cursor: pointer;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-radius: 5px;
    background-size: 200% 200%;
    background-position: 0% 50%;
}

.btn_prem:hover {
    background-position: 100% 50%
}

.btn_prem:focus {
    background-position: 100% 50%
}

.tables .big_box .options .center .dlt_btn {
    background-color: #e81e1e20;
    color: #e81e1e;


}

.tables .big_box .options .center .dlt_btn:hover {
    background-color: #e81e1e;
    color: #ffcccc;
}

.tables .big_box .options .center .dlt_btn_hide {
    -webkit-filter: saturate(0.6);
    filter: saturate(0.6);
    pointer-events: none;
    background-color: #46080880;
}


.add_form form .btn_prem {
    padding: 1vw 3vw;
}



/* --------------------- table start --------------------- */



.tables .big_box .table {
    overflow: auto;
}

.tables .big_box .table::-webkit-scrollbar {
    height: 5px;
}

.tables .big_box .table::-webkit-scrollbar-thumb {
    background: rgb(18, 145, 69, .3);

    border-radius: 5px;
}

.tables .big_box .table:hover::-webkit-scrollbar-thumb {
    background-color: #129145;

}

.tables .big_box table {
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: .9vw;
    border-radius: 12px;
}

.tables .big_box table tr {
    color: #1B2559;
    padding: 10px;
    border-bottom: .05vw solid #1B255933;
    /* border-radius: 10px; */
    transition: .3s;
    position: relative;
}

.tables .big_box table .imageSlide {
    justify-content: space-between;
    width: 100%;
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    right: 0;
    padding: .5rem 0;
    background-color: #fff;

}

.tables .big_box table .imageSlide .exit {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background-color: #A3AED0fa;
    display: flex;
    font-size: 3vw;
    border-radius: 5px;

    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    cursor: pointer;
}

.tables .big_box table .imageSlide:hover .exit {
    opacity: 1;
}

.tables .big_box table .imageSlide .img {
    flex: 1;
    position: relative;
    background-color: #00ba79;
    height: 100%;
    overflow: hidden;
    display: flex;
    border-radius: 10px;

}

.tables .big_box table .imageSlide div:not(:last-child) {
    margin-right: .5vw;

}

.tables .big_box table tr .checkbox {
    margin: 15px;
}

.tables .big_box table tr.checked {
    background-color: #20af5915;

}

.tables .big_box table tr.hidden {
    background-color: #41597775;
    opacity: .4;
}

.tables .big_box table tr td {
    height: 55px;
    text-align: center;
    padding: 5px 10px;
    text-align: left;
    font-weight: 500;
    position: relative;
    white-space: nowrap;
}

.tables .big_box table tr td .loc,
.tables .big_box table tr td .cat,
.tables .big_box table tr td .typ,
.tables .big_box table tr td .bur,
.tables .big_box table tr td .log {
    margin: 0 5px;
    height: 50%;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    border-radius: 5px;
    padding: 0 10px;
}

.tables .big_box table tr td .color-palette {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.tables .big_box table tr td .color-palette .my-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 5px;

}

.tables .big_box table tr td .typ {
    background-color: #fed5ff;
    color: #B100B5;
}

.tables .big_box table tr td .loc {
    background-color: #e81e1e20;
    color: #e81e1e;
}

.tables .big_box table tr td .bur {
    background-color: #1e41ec10;
    color: #1e41ec;
    /* background-color: #232df020;
    color: #232df0; */
}

.tables .big_box table tr td .log {
    background-color: #d41bce20;
    color: #d41bce;
}

.tables .big_box table tr td .cat {
    background-color: #ef951f20;
    color: #ef951f;
}

.tables .big_box table tr td .sat {
    background-color: #ffa73420;
    color: #ffa734;
}

.tables .big_box table tr td.rowActions {
    display: flex;
    align-items: center;
}

.tables .big_box table tr .sym {
    font-size: 1vw;
}

.tables .big_box table tr td.star {
    color: gold;
    font-size: 2vw;
}

.tables .big_box table tr td .count a,
.tables .big_box table tr td .count {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tables .big_box table tr td .count {
    width: 3vw;
    height: 3vw;
    color: #FAF8FF;
    background: linear-gradient(135deg, #0099BF, #00E0B7);
    border-radius: 5px;
    -webkit-filter: saturate(1);
    filter: saturate(1);
    transition: .3s;
    cursor: pointer;
}

.tables .big_box table tr td .last_seen {
    background: linear-gradient(135deg, #0099BF, #00E0B7);
    padding: 1vw;
    border-radius: 20px;
    color: #FAF8FF;

    white-space: nowrap;
    margin: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tables .big_box table tr td .count:hover {
    -webkit-filter: saturate(0.2);
    filter: saturate(0.2);
}



.tables .big_box table tr td .plusText {
    color: #00ba79;
    display: inline-block;
}

.tables .big_box table tr td .update {
    color: #00936f;

}

.tables .big_box table tr td .delete {
    color: #d4175d;
}

.tables .big_box table tr td .add {
    color: #f39d52;
}

.tables .big_box table tr td .album {
    color: #3526a6;
}

.tables .big_box table tr td .update,
.tables .big_box table tr td .album,
.tables .big_box table tr td .add,
.tables .big_box table tr td .delete {
    box-sizing: border-box;
    font-size: 1.2vw;
    margin: 0 5px;
    transition: .5s;
    display: inline-block;
    cursor: pointer;
    display: flex;
}



.tables .big_box table tr td .update .update_in,
.tables .big_box table tr td .add .add_in,
.tables .big_box table tr td .album .album_in,
.tables .big_box table tr td .delete .delete_in {
    transition: .5s;
    font-size: 1.5vw;
}


.tables .big_box table tr td .update:hover .update_in {
    color: #005e46;
    transform: scale(1.2);
}

.tables .big_box table tr td .album:hover .album_in {
    color: #1B2559;
    transform: scale(1.2);
}

.tables .big_box table tr td .delete:hover .delete_in {
    color: #852532;
    transform: scale(1.2);

}

.tables .big_box table tr td .delete_in_hide {
    color: #42191f7d;
    pointer-events: none;
}


.tables .big_box table tr td .add:hover .add_in {
    color: #c08e62;
    transform: scale(1.2);

}

.tables .big_box table tr td .img {
    display: flex;
    align-items: center;
    position: relative;
    width: 3.1vw;
    height: 3.1vw;
    border: 2px #595f70 solid;
    border-radius: 100%;
    transition: .4s ease-in-out;
    overflow: hidden;
    margin: 5px;
}

.tables .big_box table tr th.all {
    cursor: pointer;
    transition: .3s;
}

.tables .big_box table tr th.all:hover {
    background-color: #63748b;
    color: #bccfe2;
}


/* .tables .big_box table tr .img_td {
    cursor: pointer;

} */

/* 
*/

.tables .big_box table tr .img img {
    -o-object-fit: cover;
    object-fit: cover;
}

.tables .big_box table tr td .linkedin{
    color: #0077b5;
    font-size: 2rem;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.tables .big_box table tr td.sym_align {
    text-align: center;
}

.tables .big_box table tr td .active_yes {
    font-size: 25px;
    color: #34a030;
}

.tables .big_box table tr td .active_no {
    font-size: 25px;
    color: #f0485e;
}

.tables .big_box table tr td .love_logo {
    color: #ff3d57;
}

.tables .big_box table tr th {
    box-sizing: border-box;
    height: 55px;
    text-align: center;
    font-weight: 500;
    padding: 5px 10px;
    text-align: left;
    white-space: nowrap;
}

.tables .big_box table tr th .sym {
    display: inline-flex;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    position: relative;
    top: 2.5px;
    margin-left: .5vw;
}


.tables .big_box table tr td label {
    width: 100%;
    height: 100%;
}

.tables .big_box table tr td input[type="checkbox"] {
    width: 20px;
    height: 20px;
}



.tables .big_box .loader {
    width: 100%;
    padding-top: 10px;
}

.tables .big_box .loader .row {
    display: flex;
    height: 60px;
    padding-bottom: 9.5px;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: .05vw solid #1B255933;
}

.tables .big_box .loader .row .circle_load {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: 1rem;
    overflow: hidden;
}

.tables .big_box .loader .row .sq {
    width: 100px;
    height: 50px;
    border-radius: 20px;
    margin-right: 1rem;
    overflow: hidden;
}

.tables .big_box .loader .row .line_load {
    flex: 1;
    height: 50px;
    border-radius: 20px;
    margin-right: 1rem;

    overflow: hidden;

}

.tables .big_box .loader .load {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeholder;
    animation-name: placeholder;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: linear-gradient(to right, #12914515 8%, #0c632f30 18%, #12914515 33%);
    background-size: 1200px 100px;
    height: 100%;
    width: 100%;
    border-radius: 3px
}

@-webkit-keyframes placeholder {
    0% {
        background-position: -600px 0
    }

    100% {
        background-position: 600px 0
    }
}

@keyframes placeholder {
    0% {
        background-position: -600px 0
    }

    100% {
        background-position: 600px 0
    }
}

.tables .big_box .pages {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding-top: 20px;
}

.tables .big_box .pages .page {
    width: 2.2vw;
    height: 2.2vw;
    border-radius: 4px;
    font-size: 1.2vw;
    text-align: center;
    font-weight: 400;
    background-color: #bccfe2;
    color: #FAF8FF;
    margin: 0 10px;
    border: 2px solid transparent;
    transition: .3s;
    cursor: pointer;
}

.tables .big_box .pages .page:hover {
    background-color: #63748b;
}

.tables .big_box .pages .page_active {
    background-color: #63748b;
}


.tables .big_box .options .right {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tables .big_box .options .right .form input {
    width: 200px;
    padding: 10px 20px;
    transition: .3s;
    border: none;
    border-radius: 4px;
    font-size: 1vw;
    color: #000;
    box-shadow: 0 0 0 5px #12914526;
    margin: 7px 7px 0;
}

.tables .big_box .options .right .form input::-webkit-input-placeholder {
    color: #12914569;
}

.tables .big_box .options .right .form input::-moz-placeholder {
    color: #12914569;
}

.tables .big_box .options .right .form input:-ms-input-placeholder {
    color: #12914569;
}

.tables .big_box .options .right .form input::-ms-input-placeholder {
    color: #12914569;
}

.tables .big_box .options .right .form input::placeholder {
    color: #12914569;
}

.tables .big_box .options .right .form input:focus {
    outline: none;
    box-shadow: 0 0 0 5px #12914570;
}

.tables .big_box .return_contain {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

}

.tables .big_box .return_contain .return_icon {
    background-color: #ffdbe8;
    color: #d4175d;
    padding: .5rem;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: 1.8rem;
    border-radius: .3rem;
    display: flex;
    transition: .2s ease-in-out;
    cursor: pointer;
}

.tables .big_box .return_contain .return_icon:hover {
    -webkit-filter: brightness(0.5);
    filter: brightness(0.5);
}

.add-form form .right .btn-prem {
    padding: 1vw 3vw;
}


.input_box {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.input_box:not(:last-child) {
    margin-bottom: 20px;

}

.input_title,
.tables .form_section .background form .inside_form .other_images .title {
    margin: 10px 0;
    font-size: 1.5rem;
    text-transform: capitalize;
    color: #27286482;
}

.input_box input,
.input_box .select__control,
.input_box textarea {
    width: 100%;
    font-size: 1.2rem;
    padding: 15px 20px;
    border-radius: 10px;
    background-color: #a3aed020;
    transition: .2s ease-in-out;
    color: #272864;
}

.input_box .select__control {
    padding: 10px;
}

.input_box .select__placeholder {
    color: #1B255950;
}


.input_box input:focus,
.input_box textarea:focus .select__control select:focus {
    background-color: #A3AED050;
    outline: none;
}

.input_box input::-webkit-input-placeholder, .input_box textarea::-webkit-input-placeholder {
    color: #1B255950;
}

.input_box input::-moz-placeholder, .input_box textarea::-moz-placeholder {
    color: #1B255950;
}

.input_box input:-ms-input-placeholder, .input_box textarea:-ms-input-placeholder {
    color: #1B255950;
}

.input_box input::-ms-input-placeholder, .input_box textarea::-ms-input-placeholder {
    color: #1B255950;
}

.input_box input::placeholder,
.input_box textarea::placeholder {
    color: #1B255950;
}

.input_box .myOptions {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.input_box .myOptions .option {
    padding: .5rem;
    margin: .25rem;
    border-radius: .5rem;
    background-color: #d5d0ff;
    color: #422AFB;
    transition: .2s ease-in-out;
    cursor: pointer;
}

.input_box .myOptions .option:hover {
    background-color: #ffcfcf;
    color: #ff4848;

}

.input_box .special_input_box {
    display: flex;
    align-items: center;
    position: relative;
}

.input_box .input_save_icon {
    width: 2rem;
    height: 2rem;
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    right: 1rem;
    background-color: #422AFB;
    color: #d5d0ff;
    transition: .2s ease-in-out;
    z-index: 1;
    cursor: pointer;
}

.input_box .input_save_icon:hover {
    background-color: #2d1ca9;
}

.validate {
    font-size: .9vw;
    color: #f0485e;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 10px;
    font-weight: 500;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.validate .sym {
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2vw;
}

.img .validate {
    z-index: 1;
    background-color: #ffb2bc;
    color: #f0485e;
    padding: .5rem;
    position: absolute;
    border-radius: 5px;
}



.error_section {
    width: 100%;
    padding: 4vw;
    background-color: #fff;
    box-shadow: var(--box-shadow);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error_section .img {
    position: relative;
    width: 100%;
    height: 300px;
    margin-bottom: 3rem;
}

.error_section .error_message {
    font-size: 1.2rem;
    font-weight: 300;
    margin: 2rem 0;
    text-align: center;
}

.error_section .link {
    background-color: #d5d0ff;
    color: #422AFB;
    display: flex;
    padding: .5rem 1rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    transition: .2s ease-in-out;
}

.error_section .link .icon {
    font-size: 1.5rem;
    display: flex;
    margin-right: .5rem;
}

.error_section .link:hover {
    -webkit-filter: brightness(50%);
    filter: brightness(50%);
}


/* --------------------- table end --------------------- */



/* --------------------- add start --------------------- */


.tables .form_section {
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    background-color: #f0f2f5cb;
    -webkit-font-smoothing: antialiased;
    overscroll-behavior-y: none;
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tables .form_section .background {
    width: 50%;
    height: 90%;
    background-color: #fff;
    box-shadow: 0 0 10px #3335;
    padding: var(--padding) var(--padding-2);
    overscroll-behavior-y: none;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.tables .form_section .background .header {
    justify-content: space-between;
    padding: var(--padding);
    border-bottom: 2px solid var(--line-color);
    display: flex;
    align-items: center;
}

.tables .form_section .background .header .form_section_title {
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
}

.tables .form_section .background .message_title {
    margin-top: 20px;
}

.tables .form_section .background .message {
    border: solid var(--line-color);
    margin-top: 5px;
    border-radius: 10px;
    padding: 10px;
}

.tables .form_section .background .header .exit {
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.tables .form_section .background .header .exit:hover {
    transform: scale(1.1);
}

.tables .form_section .background form {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.tables .form_section .background form .inside_form {
    padding: var(--padding);
    overflow: auto;
    margin: var(--margin) 0;
    flex: 1;
    display: flex;
    flex-direction: column;
}


.tables .form_section .background form .inside_form .other_images .images {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 1%;
    border-radius: 20px;
    border: dashed 5px;
    margin-top: 1rem;
    margin-bottom: 20px;
}

.tables .form_section .background form .inside_form .other_images .images .img {
    position: relative;
    margin: 1%;
    width: 48%;
    height: 200px;
    border: dashed 5px;
}

.tables .form_section .background form .inside_form .other_images .mobile_images .img {
    height: 300px;
}

.tables .form_section .background form .inside_form .device {
    display: flex;
    margin-bottom: 30px;
    justify-content: center;

}

.tables .form_section .background form .inside_form .device .desktop,
.tables .form_section .background form .inside_form .device .mobile {
    width: 3rem;
    height: 3rem;
    border-radius: .5rem;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px;
    margin: 0 .5rem;
    transition: .2s ease-in-out;
    cursor: pointer;
}

.tables .form_section .background form .inside_form .device .active {
    background-color: #1B2559;
    color: #fff;
}

.tables .form_section .background form .inside_form .device .error {
    background-color: #ff3d57;
    color: #fff;
}

.tables .form_section .background form .inside_form .device .desktop:hover,
.tables .form_section .background form .inside_form .device .mobile:hover {
    -webkit-filter: contrast(.2);
    filter: contrast(.2);
}


.tables form .img_box {
    width: 100%;
    display: flex;
    position: relative;
    border: dashed 5px;
    padding: 20px;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
}

.uploadInput {
    width: 100%;
    height: 200%;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
}

.tables form .img_box:first-child {
    margin-bottom: 30px;
}

.tables form .img {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    border-radius: 10px;
    background-color: transparent;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 220px;
    margin-bottom: 1.5rem;
}

.tables form .img_mobile_box .img {
    height: 300px;
    width: 50%;
}

.tables form .img:focus {
    box-shadow: 0 0 0 10px #ffb6d95b;
}




.tables form select .select__control select {
    width: auto;
    font-size: 1.2rem;
    padding: 15px 20px;
    border-radius: 10px;
    background-color: #a3aed020;
    transition: .2s ease-in-out;
    color: #272864;
}

.tables form select:focus {
    background-color: #A3AED050;
    outline: none;
}

.tables form .role {
    width: 100%;
    font-size: 1vw;
    font-weight: 500;
    text-align: center;
}


.input_radio {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    transition: .3s;
}

.input_radio:focus {
    box-shadow: 0 0 0 10px #ffb6d95b;

}

.input_radio input[type='radio'] {
    width: 2rem;
    height: 2rem;
    margin: 0 20px;
}

.input_radio input[type='radio']:focus {
    box-shadow: none;
}

.tables .form_section .background form .inside_form::-webkit-scrollbar {
    width: 5px;
}


.tables .form_section .background form .inside_form::-webkit-scrollbar-thumb {
    background: #31428750;
    border-radius: 5px;
}

.tables .form_section .background form .inside_form:hover::-webkit-scrollbar-thumb {
    background-color: #314287;
}

.tables .form_section .background .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--padding);
    border-top: 2px solid var(--line-color);
}

.tables .form_section .background .actions .discard,
.tables .form_section .background .actions .reset,
.tables .form_section .background .actions .submit {
    padding: 10px 20px;
    font-size: 1.2rem;
    margin: 0 .5rem;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    transition: -webkit-filter .2s ease-in-out;
    transition: filter .2s ease-in-out;
    transition: filter .2s ease-in-out, -webkit-filter .2s ease-in-out;
}

.tables .form_section .background .actions .reset {
    background-color: #EE7964;
    color: #fff;
}

.tables .form_section .background .actions .submit {
    background-color: #1B2559;
    color: #f4f4f4;
}

.tables .form_section .background .actions .discard {
    background-color: #A3AED040;
    color: #1B254B90;
}

.tables .form_section .background .actions .discard:hover,
.tables .form_section .background .actions .reset:hover,
.tables .form_section .background .actions .submit:hover {
    -webkit-filter: saturate(3);
    filter: saturate(3);
}

.multiple-color-picker {
    max-width: 300px;
}

.selected-colors {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.color-item {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

.color-preview {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 0.5rem;
}

.color-hex {
    font-size: 0.9rem;
    margin-right: 0.5rem;
}

.remove-color {
    background: none;
    border: none;
    color: #ff0000;
    cursor: pointer;
}

.color-picker-with-input {
    display: flex;
    flex-direction: column;
}

.color-input-form {
    display: flex;
    width: 100%;
    margin-top: 1rem;
}

.color-input {
    flex-grow: 1;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
}

.add-color-btn {
    padding: 5px 10px;
    background-color: #129145;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    flex: .5;
}

.color-input-form .color-input {
    padding: 10px 20px;
    font-size: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    flex: 1;
}

.ring-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
}

.ring-range-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.ring-range-input {
    flex-grow: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
}

.ring-range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgb(18, 145, 69);
    cursor: pointer;
}

.ring-range-input::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgb(18, 145, 69);
    cursor: pointer;
}

.ring-value-display {
    min-width: 40px;
    text-align: right;
}

.input_box img {
    width: 200px;
    height: 200px;
    transition: .3s ease-in-out;
    margin-top: 1rem;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 20px;
}


/* --------------------- add end --------------------- */

@media only screen and (max-width: 1024px) {

    .tables .big_box table,
    .tables .big_box table tr .sym {
        font-size: 1.5vw;
    }

    .tables .big_box table tr td,
    .tables .big_box table tr th {
        padding: 5px 10px;
    }

    .tables .big_box table tr .sym {
        text-align: center;
    }

    .tables .big_box table tr td .update,
    .tables .big_box table tr td .update .update_in,
    .tables .big_box table tr td .add .add_in,
    .tables .big_box table tr td .album .album_in,
    .tables .big_box table tr td .delete .delete_in {
        font-size: 2vw;
    }


    .tables .big_box .options .left .sortBox {
        font-size: 1.5vw;
        margin: 0;
    }

    .tables .big_box .options .center {
        margin: 0;
    }

    .tables .big_box .options .center .add_btn,
    .tables .big_box .options .center .dlt_btn {
        font-size: 1.8vw;
    }

    .tables .big_box .options .right .form input {
        font-size: 1.5vw;
        margin: 0;
        margin-left: 2rem;
    }
}

@media only screen and (max-width: 768px) {

    .tables .big_box table tr td .update,
    .tables .big_box table tr td .update .update_in,
    .tables .big_box table tr td .add .add_in,
    .tables .big_box table tr td .album .album_in,
    .tables .big_box table tr td .delete .delete_in {
        font-size: 3vw;
    }

    .tables .big_box table,
    .tables .big_box table tr .sym {
        font-size: 2vw;
    }

    .tables .big_box table tr td .img {
        width: 5.5vw;
        height: 5.5vw;
    }



    .tables .form_section .background {
        width: 70%;
        padding: 1rem 2rem;
    }

    .tables form .role {
        font-size: 2vw;
    }

}

@media only screen and (max-width: 500px) {

    .tables .big_box table tr td .update,
    .tables .big_box table tr td .update .update_in,
    .tables .big_box table tr td .add .add_in,
    .tables .big_box table tr td .album .album_in,
    .tables .big_box table tr td .delete .delete_in {
        font-size: 6vw;
    }

    .tables .big_box table,
    .tables .big_box table tr .sym {
        font-size: 3vw;
    }

    .tables .big_box table tr td .img {
        width: 8vw;
        height: 8vw;
    }

    .tables .big_box .options .right {
        margin-top: 30px;
    }

    .tables .big_box .options .left {
        justify-content: flex-start;
    }


    .tables .big_box .options .right .form,
    .tables .big_box .options .right .form input {
        width: 100%;
        margin: 0;
        font-size: 2.5vw;
    }

    .tables .big_box .options .center {
        justify-content: flex-end;
    }

    .tables .big_box .options .right,
    .tables .big_box .options .center,
    .tables .big_box .options .left {
        min-width: 40%;
        width: auto;
        flex: 1;
    }

    .tables .big_box .options {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .tables .big_box .options .center .add_btn,
    .tables .big_box .options .center .dlt_btn {
        margin: 0;
        padding: 2.5vw 4vw;
        text-transform: uppercase;
        margin-left: .5rem;
    }

    .tables .big_box .options .center .add_btn,
    .tables .big_box .options .center .dlt_btn,
    .tables .big_box .options .left .sortBox {
        font-size: 2.5vw;
    }

    .tables .big_box .options .left .sym {
        font-size: 5vw;
        margin-left: 1rem;
    }

    .tables .form_section .background {
        width: 90%;
        padding: 1rem;
    }

    .tables .form_section .background form .inside_form .other_images .images .img {
        position: relative;
        margin: 2%;
        width: 100%;
        height: 150px;
        border: none;
        border-radius: 0;

    }

    .tables .form_section .background form .inside_form .other_images .images .img:not(:last-child) {
        border-bottom: 5px dashed;
    }

    .tables .form_section .background .actions .discard,
    .tables .form_section .background .actions .reset,
    .tables .form_section .background .actions .submit {
        font-size: .9rem;
    }

    .input_title,
    .tables .form_section .background .header .form_section_title,
    .tables .form_section .background form .inside_form .other_images .title {
        font-size: 1.2rem;
    }

    .input_radio input[type='radio'] {
        width: 1.5rem;
        height: 1.5rem;
    }


    .tables .form_section .background form .inside_form .other_images .images .img {
        height: 140px;
        border-radius: 20px;

    }

    .tables form .img_box .img {
        height: 140px;
        width: 110%;
    }

    .tables form .img_mobile_box .img {
        width: 90%;
        height: 320px;

    }


    .tables .form_section .background form .inside_form .other_images .mobile_images {
        justify-content: center
    }

    .tables .form_section .background form .inside_form .other_images .mobile_images .img {
        height: 300px;
        width: 60%;
        border-radius: 20px;
        margin: 1rem 0;
    }


}

@media only screen and (max-width: 425px) {}

@media only screen and (max-width: 390px) {}

@media only screen and (max-width: 320px) {}