.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-page .general_data {
    display: flex;
    width: 100%;
    margin-top: var(--margin);
    min-height: 5rem;
}

.home-page .general_data .data_box {
    flex: 1;
    border-radius: var(--border-radius);
    background-color: #fff;
    box-shadow: var(--box-shadow);
    padding: var(--padding);
    display: flex;

}

.home-page .general_data .data_box:nth-child(2) {
    margin: 0 var(--padding);

}

.home-page .general_data .data_box .icon {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    background-color: rgb(18, 145, 69, .1);
    color: rgb(18, 145, 69);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    margin-right: 1rem;
}

.home-page .general_data .data_box .data {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.home-page .general_data .data_box .data .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-page .general_data .data_box .data .info .info_title {
    color: #A3AED0;
    font-size: .8rem;
    margin-bottom: 5px;
}

.home-page .general_data .data_box .data .info .value {
    color: #1B2559;
    font-weight: 600;
    font-size: var(--title-font);
    text-transform: capitalize;
}



.home-page .general_data .data_box .data .time_options_title {
    font-size: .8rem;
    color: #A3AED0;
    display: flex;
    font-weight: 600;
    cursor: pointer;
    border: none;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
}

.home-page .general_data .data_box .data .time_options_title option {
    font-size: 1rem;
    padding: 1rem;
}

.home-page .data_section_1,
.home-page .data_section_2 {
    margin-top: var(--margin);
    width: 100%;
    display: flex;
    gap: var(--padding);
}

.home-page .data_section_1 .barChart {
    background-color: #fff;
    border-radius: var(--border-radius);
    padding: var(--padding-2);
    width: 66.66%;
    margin-right: var(--margin);
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    box-shadow: var(--box-shadow);

}

.home-page .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.home-page .header .title {

    font-size: var(--title-font);
    font-weight: 600;
}

.home-page .header .options {
    color: #A3AED0;
    font-size: .8rem;
    font-weight: 600;
    cursor: pointer;
    /* height: max-content; */
}

.home-page .data_section_1 .barChart .chart {
    margin-top: var(--margin-2);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.home-page .data_section_1 .recent_activity {
    background-color: #fff;
    border-radius: var(--border-radius);
    padding: var(--padding-2);
    box-shadow: var(--box-shadow);
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    flex: 1;
}


.home-page .data_section_1 .recent_activity .title {
    font-size: var(--title-font);
    font-weight: 600;
}

.home-page .data_section_1 .recent_activity .activities {
    margin-top: var(--margin-2);
}

.home-page .data_section_1 .recent_activity .activity {
    margin-bottom: 20px;
}

.home-page .data_section_1 .recent_activity .activity .li {
    display: flex;
    align-items: center;
}

.home-page .data_section_1 .recent_activity .activity .li .circle {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #129145;

}

.home-page .data_section_1 .recent_activity .activity .li .circle_2 {
    background-color: #1e41ec;
}

.home-page .data_section_1 .recent_activity .activity .li .timing {
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: 20px;
}

.home-page .data_section_1 .recent_activity .text {
    color: #A3AED0;
    margin-top: 5px;
    margin-left: 40px;
    font-size: .8rem;
}

.home-page .data_section_1 .tables {
    padding-top: 0;
    flex: 3;
}
.home-page .data_section_1 .tables .big_box{
    border-radius: var(--border-radius);
}
.home-page .data_section_2 .links_and_devices {
    display: flex;
    width: 40%;
    flex-direction: column;
    margin-right: var(--margin);

}

.home-page .external_links,
.home-page .devices {
    width: 100%;
    background-color: #fff;
    border-radius: var(--border-radius);
    padding: var(--padding-2);
    box-shadow: var(--box-shadow);
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
}



.home-page .external_links .title {
    font-size: var(--title-font);
    font-weight: 600;
    margin-bottom: var(--margin-2);
}


.home-page .external_links .links .link {
    border-bottom: 2px solid var(--line-color);
    padding-bottom: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    color: #A3AED0;
    align-items: center;
    cursor: pointer;
    transition: .3s;
}

.home-page .external_links .links .link:hover {
    color: #422AFB;
    border-bottom: 2px solid #422AFB;
}


.home-page .external_links .links .link .icon {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-page .devices {
    margin-top: var(--margin);
}

.home-page .devices .devices_doughnut {
    margin-top: var(--margin-2);
    height: 200px;
}

.home-page .data_section_2 .doughnut_chart {
    width: 60%;
    background-color: #fff;
    box-shadow: var(--box-shadow);
    padding: var(--padding-2);
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    flex-direction: column;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
}

.home-page .data_section_2 .doughnut_chart .myDoughnut {
    padding: var(--padding);
    width: 100%;
    margin-top: var(--margin-2);

}

.home-page .browser {
    width: 100%;
    margin-top: 0;
}


@media only screen and (max-width: 1024px) {
    .home-page .general_data {
        flex-wrap: wrap;
    }

    .home-page .general_data .data_box {
        min-width: 40%;
    }

    .home-page .general_data .data_box:nth-child(2) {
        margin-right: 0;
    }

    .home-page .general_data .data_box:last-child {
        margin: 20px 0;
    }
    .home-page .data_section_1, .home-page .data_section_2{
        flex-direction: column;
    }

}

@media only screen and (max-width: 768px) {

    .home-page .data_section_1,
    .home-page .data_section_2 {
        flex-direction: column;
        margin: 0;
    }

    .home-page .data_section_1 .barChart,
    .home-page .data_section_1 .recent_activity {
        width: 100%;
        margin-bottom: 20px;
    }


    .home-page .external_links,
    .home-page .data_section_2 .links_and_devices,
    .home-page .data_section_2 .doughnut_chart {
        width: 100%;
        margin: 0;
    }

    .home-page .data_section_2 .links_and_devices {
        margin-bottom: 20px;
    }


    .home-page .devices {
        margin-top: 0;
        margin-bottom: 20px;
    }

    .home-page .devices .devices_doughnut {
        height: 300px;
    }

    .home-page .external_links {
        order: 2;
    }
    .home-page .data_section_1 .tables{
        padding: 0;
    }

}

@media only screen and (max-width: 500px) {
    .home-page .general_data .data_box {
        min-width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }

    .home-page .general_data .data_box:nth-child(2) {
        margin: 0;
    }

    .home-page .data_section_1 .barChart .chart {
        height: 300px;
    }

    .home-page .general_data .data_box .data .info {
        margin-right: .5rem;
    }
}

/* @media only screen and (max-width: 1024px) {}
  
  @media only screen and (max-width: 768px) {}
  
  @media only screen and (max-width: 500px) {}
  
  @media only screen and (max-width: 425px) {}
  
  @media only screen and (max-width: 390px) {}
  
  @media only screen and (max-width: 320px) {} */